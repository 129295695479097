@media (pointer:fine) {
  section.NSadmin .Navigation {
    margin: 1rem;
    padding: 1rem 0;
    width: calc(100% - 2rem);
    font-family: "Aleo-Regular";
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  section.NSadmin .Navigation .svg-c1 {
    fill: var(--appC-r6);
  }

  section.NSadmin .Navigation .svg-c2 {
    fill: var(--appC-r1);
  }

  section.NSadmin .Navigation>span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  section.NSadmin .Navigation>span>svg {
    margin-right: 0.4rem;
    height: 2rem;
    width: auto;
  }

  section.NSadmin .Navigation>span>a {
    color: var(--appC-r5);
  }

  section.NSadmin .Navigation>ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    gap: 2rem;
  }

  section.NSadmin .Navigation>ul>li {
    color: var(--appC-r5);
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  section.NSadmin .Navigation>ul>li>svg {
    width: auto;
    height: 2rem;
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {}
}