@media (pointer:fine) {
  section.NSadmin .open {
    display: table
  }

  section.NSadmin .close {
    display: none
  }

  section.NSadmin .Message {
    aspect-ratio: 1 / 2;
  }

  section.NSadmin .Message>div {
    margin-bottom: 1rem;
    padding: 2rem;
    border: 1px dashed var(--appB-r3);
    border-radius: 1rem;
    background-image: var(--appG-t6);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Message>h2 {
    margin: 2rem 1rem;
    padding: 0;
    color: var(--appC-r4);
    font-size: 2.2rem
  }

  section.NSadmin .Message>button {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Message table th {
    cursor: pointer;
  }

  section.NSadmin .Message table p {
    width: 100%;
    font-size: 1.6rem;
    color: var(--appC-r5);
  }

  section.NSadmin .Message button {
    margin: 1rem;
    border: 0;
    padding: 0;
    width: calc(100% - 2rem);
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    cursor: pointer;
  }

  section.NSadmin .Message button svg {
    height: 2rem;
    width: auto;
  }

  section.NSadmin .Message button svg g {
    fill: var(--appC-r4);
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {}
}