@media (pointer:fine) {
  section.NSadmin .Menutop>h2 {
    margin: 2rem 1rem;
    padding: 0;
    color: var(--appC-r4);
    font-size: 2.2rem;
  }

  section.NSadmin .Menutop>input[type=text] {
    box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Menutop>button {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Menutop table a {
    width: 100%;
  }

  section.NSadmin .Menutop table p {
    width: 100%;
    font-size: 1.6rem
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {}
}