@media (pointer:coarse) {
  @media (min-width: 2561px) and (max-width: 3840px) {
    html {
      font-size: 10px
    }
  }

  @media (min-width: 1441px) and (max-width: 2560px) {
    html {
      font-size: 8px
    }
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    html {
      font-size: 7px
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    html {
      font-size: 6.5px
    }
  }

  @media (min-width: 426px) and (max-width: 768px) {
    html {
      font-size: 6px
    }
  }

  @media (min-width: 376px) and (max-width: 425px) {
    html {
      font-size: 5.5px
    }
  }

  @media (min-width: 321px) and (max-width: 375px) {
    html {
      font-size: 5px
    }
  }

  @media (min-width: 1px) and (max-width: 320px) {
    html {
      font-size: 4.2px
    }
  }
}

@media (pointer:fine) {
  @media (min-width: 1921px) {
    html {
      font-size: 14px
    }
  }

  @media (min-width: 1681px) and (max-width: 1920px) {
    html {
      font-size: 12px
    }
  }

  @media (min-width: 1601px) and (max-width: 1680px) {
    html {
      font-size: 10.25px
    }
  }

  @media (min-width: 1441px) and (max-width: 1600px) {
    html {
      font-size: 10px
    }
  }

  @media (min-width: 1367px) and (max-width: 1440px) {
    html {
      font-size: 9px
    }
  }

  @media (min-width: 1281px) and (max-width: 1366px) {
    html {
      font-size: 8.5px
    }
  }

  @media (min-width: 1153px) and (max-width: 1280px) {
    html {
      font-size: 8px
    }
  }

  @media (min-width: 1025px) and (max-width: 1152px) {
    html {
      font-size: 7px
    }
  }

  @media (min-width: 801px) and (max-width: 1024px) {
    html {
      font-size: 6.5px
    }
  }

  @media (min-width: 1px) and (max-width: 800px) {
    html {
      font-size: 5px
    }
  }
}