@font-face {
	font-family: "Aleo-Light";
	src: local("Aleo-Light"), url(Aleo-Light.ttf) format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "Aleo-Regular";
	src: local("Aleo-Regular"), url(Aleo-Regular.ttf) format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "Aleo-Bold";
	src: local("Aleo-Bold"), url(Aleo-Bold.ttf) format("truetype");
	font-display: swap;
}