@media (pointer:fine) {
  section.NSadmin .Stat {
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    width: calc(100% - 2rem);
    background-image:
      url(../../../../../../img/m1.png),
      var(--appG-t6),
      var(--appG-t2);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    font-family: "Aleo-Regular";
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    box-shadow:
      inset 0 0 10px 0 rgba(0, 0, 0, 0.2),
      4px 10px 8px 4px rgba(0, 0, 0, 0.5);
  }

  section.NSadmin .Stat>h2 {
    margin: 2rem 1rem;
    padding: 0;
    color: var(--appC-r5);
    font-size: 2.2rem;
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {}
}