@media (pointer:fine) {
  section.NSadmin .Settings>h2 {
    margin: 2rem 1rem;
    padding: 0;
    color: var(--appC-r4);
    font-size: 2.2rem;
  }

  section.NSadmin .Settings>p {
    margin: 0.2rem 1rem;
    padding: 0;
    width: 100%;
    color: var(--appC-r4);
    font-size: 1.2rem;
  }

  section.NSadmin .Settings>label {
    margin: 0.2rem 1rem;
    padding: 0 0.5rem;
    width: 100%;
    color: var(--appC-r4);
    font-size: 1.2rem;
  }

  section.NSadmin .Settings>input[type=text] {
    box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Settings>button {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 40%)
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {}
}