@media (pointer:fine) {
  section.NSadmin .Media>h2 {
    margin: 2rem 1rem;
    padding: 0;
    color: var(--appC-r4);
    font-size: 2.2rem
  }

  section.NSadmin .Media>input[type=text] {
    box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Media>fieldset>input[type=radio] {
    width: calc(100% / 6 - 2rem)
  }

  section.NSadmin .Media>fieldset>input[type=radio]:checked {
    margin: 0.25rem;
    width: calc(100% / 6 - 0.5rem);
  }

  section.NSadmin .Media>button {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Media>.medialist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  section.NSadmin .Media>.medialist .mediaitem {
    border-radius: 1rem;
    margin: 1rem;
    width: calc(100% / 4 - 2rem);
    aspect-ratio: 1 / 1;
    background-color: var(--appC-r5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 40%);
  }

  section.NSadmin .Media>.medialist .thumb {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 16 / 9;
  }

  section.NSadmin .Media>.medialist p {
    padding: 2rem 1rem;
    color: var(--appC-r4);
  }

  section.NSadmin .Media>.medialist button {
    margin: 1rem;
    border: 0;
    padding: 0;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    cursor: pointer;
  }

  section.NSadmin .Media>.medialist button svg {
    height: 2rem;
    width: auto;
  }

  section.NSadmin .Media>.medialist button svg g {
    fill: var(--appC-r4);
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {}
}