@media (pointer:fine) {
  section.NSadmin .Sidebar>h2 {
    margin: 2rem 1rem;
    padding: 0;
    color: var(--appC-r4);
    font-size: 2.2rem;
  }

  section.NSadmin .Sidebar>ul {
    margin: 1rem;
    width: calc(100% - 2rem);
  }

  section.NSadmin .Sidebar>ul>li {
    margin-bottom: 0.5rem;
    border-radius: 1rem;
    padding: 0.4rem;
    font-size: 1.4rem;
    transition-duration: 0.5s;
  }

  section.NSadmin .Sidebar>ul>li:hover {
    border-radius: 1rem;
    background-image: var(--appG-t6);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }

  section.NSadmin .Sidebar>ul>li>a {
    color: var(--appC-r4);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    transition-duration: 0.25s;
  }

  section.NSadmin .Sidebar>ul>li:hover>a {
    border-radius: 0.8rem;
    background-image: var(--appG-t6);
    color: var(--appC-r5);
  }

  section.NSadmin .Sidebar>ul>li>a>svg {
    margin-right: 1rem;
    padding: 0.5rem;
    border-radius: 0.8rem;
    width: 4rem;
    height: 4rem;
    background-color: var(--appC-r3);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 40%);
    transition-duration: 0.5s;
  }

  section.NSadmin .Sidebar>ul>li:hover>a>svg {
    border-radius: 0.8rem 0 0 0.8rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }

  section.NSadmin .Sidebar>ul>li>a>svg .svg-c1,
  section.NSadmin .Sidebar>ul>li:hover>a>svg .svg-c1 {
    fill: var(--appC-r3);
  }

  section.NSadmin .Sidebar>ul>li>a>svg .svg-c2 {
    fill: var(--appC-r4);
  }

  section.NSadmin .Sidebar>ul>li:hover>a>svg .svg-c2 {
    fill: var(--appC-r5);
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {}
}