@keyframes preload {

  0%,
  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.preload {
  background-image: var(--appG-t1), var(--appG-t6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% + 0.5rem);
  height: 100%;
  box-shadow: inset 0 0 60px 20px rgba(0, 0, 0, 0.2);
  z-index: 1100;
}

.preload>img {
  width: auto;
  height: 20rem;
  animation: preload;
  animation-duration: 1s;
}

/*  */

@keyframes blurload {
  0% {
    filter: blur(5px);
  }

  100% {
    filter: blur(0px);
  }
}

.blurload {
  animation: blurload 1.6s;
}

/*  */

@keyframes fadeload {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeload {
  animation: fadeload 1.6s;
}