@media (pointer:fine) {
  .app .NSuser .open {
    display: block;
  }

  .app .NSuser .close {
    display: none;
  }

  .app .NSuser {
    padding: 1rem;
    min-height: 100vh;
    background-image: var(--cliG-t5o10), var(--cliG-t1);
    font-family: "Aleo-Regular";
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .app .NSuser>.avatar {
    margin-bottom: 2rem;
    border-radius: 50%;
    border: 1rem solid rgba(0, 0, 0, 0.2);
    width: calc(100% / 8);
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .app .NSuser>.avatar>img {
    border-radius: 50%;
    width: 100%;
    height: auto;
  }

  .app .NSuser>h2 {
    margin-bottom: 2rem;
    font-size: 2rem;
    color: var(--cliC-r2);
  }

  .app .NSuser>.iconBar {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
  }

  .app .NSuser>.iconBar>a {
    text-decoration: none;
  }

  .app .NSuser>.iconBar button {
    margin: 0 0.5rem;
    border-radius: 50%;
    border: 0;
    background-image: var(--cliG-t2);
    width: 4rem;
    aspect-ratio: 1/1;
    color: var(--cliC-r1);
    cursor: pointer;
    opacity: 0.75;
    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.5);
    transition-duration: 0.25s;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .app .NSuser>.iconBar button:hover {
    opacity: 1;
  }

  .app .NSuser>.iconBar button>svg {
    height: 2rem;
    width: auto;
  }

  .app .NSuser>.iconBar button>svg>g {
    fill: var(--cliC-r1);
  }

  .app .NSuser>.userSettings {
    width: calc(100% / 2);
    min-height: 34rem;
  }

  .app .NSuser>.userSettings table thead {
    font-size: 1.8rem;
    color: var(--cliC-r2);
  }

  .app .NSuser>.userSettings table tbody {
    font-size: 1.2rem;
    color: var(--cliC-r2);
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {
    .app .NSuser .open {
      display: block;
    }

    .app .NSuser .close {
      display: none;
    }

    .app .NSuser {
      padding: 3rem;
      min-height: 100vh;
      background-image: var(--cliG-t5o10), var(--cliG-t1);
      font-family: "Aleo-Regular";
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    .app .NSuser>.avatar {
      margin-bottom: 2rem;
      border-radius: 50%;
      border: 1rem solid rgba(0, 0, 0, 0.2);
      width: calc(100% / 2);
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    .app .NSuser>.avatar>img {
      border-radius: 50%;
      width: 100%;
      height: auto;
    }

    .app .NSuser>h2 {
      margin-bottom: 2rem;
      font-size: 4rem;
      color: var(--cliC-r2);
    }

    .app .NSuser>.iconBar {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
    }

    .app .NSuser>.iconBar>a {
      text-decoration: none;
    }

    .app .NSuser>.iconBar button {
      margin: 0 0.5rem;
      border-radius: 50%;
      border: 0;
      background-image: var(--cliG-t2);
      width: 10rem;
      aspect-ratio: 1/1;
      color: var(--cliC-r2);
      cursor: pointer;
      opacity: 0.75;
      box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.5);
      transition-duration: 0.25s;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }

    .app .NSuser>.iconBar button:hover {
      opacity: 1;
    }

    .app .NSuser>.iconBar button>svg {
      height: 5rem;
      width: auto;
    }

    .app .NSuser>.iconBar button>svg>g {
      fill: var(--cliC-r1);
    }

    .app .NSuser>.userSettings {
      width: calc(100% / 1);
      min-height: 40vh;
    }

    .app .NSuser>.userSettings table thead {
      font-size: 3.4rem;
      color: var(--cliC-r2);
    }

    .app .NSuser>.userSettings table tbody {
      font-size: 2.6rem;
      color: var(--cliC-r2);
    }
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {
    .app .NSuser .open {
      display: block;
    }

    .app .NSuser .close {
      display: none;
    }

    .app .NSuser {
      padding: 3rem;
      min-height: 100vh;
      background-image: var(--cliG-t5o10), var(--cliG-t1);
      font-family: "Aleo-Regular";
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    .app .NSuser>.avatar {
      margin-bottom: 2rem;
      border-radius: 50%;
      border: 1rem solid rgba(0, 0, 0, 0.2);
      width: calc(100% / 6);
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    .app .NSuser>.avatar>img {
      border-radius: 50%;
      width: 100%;
      height: auto;
    }

    .app .NSuser>h2 {
      margin-bottom: 2rem;
      font-size: 3rem;
      color: var(--cliC-r2);
    }

    .app .NSuser>.iconBar {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
    }

    .app .NSuser>.iconBar>a {
      text-decoration: none;
    }

    .app .NSuser>.iconBar button {
      margin: 0 0.5rem;
      border-radius: 50%;
      border: 0;
      background-image: var(--cliG-t2);
      width: 6rem;
      aspect-ratio: 1/1;
      color: var(--cliC-r2);
      cursor: pointer;
      opacity: 0.75;
      box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.5);
      transition-duration: 0.25s;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }

    .app .NSuser>.iconBar button:hover {
      opacity: 1;
    }

    .app .NSuser>.iconBar button>svg {
      height: 3rem;
      width: auto;
    }

    .app .NSuser>.iconBar button>svg>g {
      fill: var(--cliC-r1);
    }

    .app .NSuser>.userSettings {
      width: calc(100% / 1);
      min-height: 34rem;
    }

    .app .NSuser>.userSettings table thead {
      font-size: 2.6rem;
      color: var(--cliC-r2);
    }

    .app .NSuser>.userSettings table tbody {
      font-size: 1.8rem;
      color: var(--cliC-r2);
    }
  }
}