@media (pointer:fine) {
  section.NSadmin .Category>h2 {
    margin: 2rem 1rem;
    padding: 0;
    color: var(--appC-r4);
    font-size: 2.2rem;
  }

  section.NSadmin .Category>input[type=text] {
    box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Category>fieldset>input[type=radio] {
    width: calc(100% / 4 - 2rem);
  }

  section.NSadmin .Category>fieldset>input[type=radio]:checked {
    margin: 0.25rem;
    width: calc(100% / 4 - 0.5rem);
  }

  section.NSadmin .Category>button {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 40%)
  }

  section.NSadmin .Category table p {
    width: 100%;
    font-size: 1.6rem
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {}
}