@media (pointer:fine) {
  section.NSadmin .Info {
    margin: 1.5rem;
    border-radius: 1rem;
    padding: 1rem;
    width: calc(100% / 1 - 3rem);
    height: auto;
    font-family: "Aleo-Regular";
    display: flex;
    position: relative;
    aspect-ratio: 1 / 1;
  }

  section.NSadmin .Info .circle1 {
    border: 0.1rem solid var(--appB-r3);
    border-radius: 50%;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    background-image:
      var(--appG-t2),
      var(--appG-t6);
    display: flex;
    box-shadow:
      0 0 8px 2px rgba(60, 60, 60, 0.6),
      inset 0 0 4px 1px rgba(50, 50, 50, 0.4),
      inset 0 80px 40px 20px rgba(0, 0, 0, 0.1),
      inset 10px 20px 15px 10px rgba(55, 55, 55, 0.1);
  }

  @keyframes circleRotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }

  section.NSadmin .Info .circle2::before {
    content: "";
    border-radius: 50%;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    background-image: var(--appG-t3);
    position: absolute;
    animation: circleRotate 4s infinite linear;
    z-index: 888;
  }

  section.NSadmin .Info .circle2 {
    margin: 7rem;
    border: 0.4rem solid var(--appC-r6);
    border-radius: 50%;
    width: calc(100% - 14rem);
    height: auto;
    aspect-ratio: 1 / 1;
    background-image:
      var(--appG-t2),
      var(--appG-t6);
    display: flex;
    position: relative;
    box-shadow:
      0 0 8px 2px rgba(0, 0, 0, 0.6),
      5px 10px 16px 8px rgba(0, 0, 0, 0.6),
      10px 16px 16px 16px rgba(0, 0, 0, 0.2),
      inset 5px 0 4px 2px rgba(0, 0, 0, 0.4),
      inset 5px 80px 40px 20px rgba(0, 0, 0, 0.4),
      inset 10px 30px 15px 10px rgba(0, 0, 0, 0.2);
  }

  section.NSadmin .Info .circle3 {
    margin: 1.5rem;
    border: 0.2rem solid var(--appC-r6);
    border-radius: 50%;
    width: calc(100% - 3rem);
    height: auto;
    aspect-ratio: 1 / 1;
    background-image:
      var(--appG-t6),
      url(https://images.squarespace-cdn.com/content/v1/5fe4caeadae61a2f19719512/1615739943971-KX4G87OEO44LR5VJ0926/7.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    box-shadow:
      2px 6px 8px 2px rgba(0, 0, 0, 0.6),
      inset 20px 10px 10px 50px rgba(0, 0, 0, 0.4),
      inset 0 40px 10px 80px rgba(0, 0, 0, 0.4);
    z-index: 999;
  }

  section.NSadmin .Info .circle3 h2 {
    color: var(--appC-r6);
    font-size: 8rem;
    text-shadow: 6px 6px 8px rgba(0, 0, 0, 0.6);
  }

  /*  */

  section.NSadmin .Info .fogbg {
    width: 100%;
    height: 100%;
    position: absolute
  }

  section.NSadmin .Info .fogbg .container {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden
  }

  section.NSadmin .Info .fogbg .img {
    position: absolute;
    height: 100vh;
    width: 300vw
  }

  section.NSadmin .Info .fogbg .img--first {
    background: url("../../../../../../img/fog1.png") repeat-x;
    background-size: contain;
    background-position: center;
    height: 100%;
    animation: marquee 120s linear infinite
  }

  section.NSadmin .Info .fogbg .img--second {
    background: url("../../../../../../img/fog2.png") repeat-x;
    background-size: contain;
    background-position: center;
    height: 100%;
    animation: marquee 200s linear infinite
  }

  @keyframes marquee {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    90% {
      opacity: 1;
    }

    100% {
      transform: translate3d(-200vw, 0, 0);
      opacity: 0;
    }
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {
    section.NSadmin .Info {
      margin: 1.5rem;
      border-radius: 1rem;
      padding: 1rem;
      width: calc(100% / 1 - 3rem);
      height: auto;
      display: flex;
      position: relative;
      aspect-ratio: 1 / 1;
    }

    section.NSadmin .Info .circle1 {
      border: 0.1rem solid var(--appB-r3);
      border-radius: 50%;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      background-image:
        var(--appG-t2),
        var(--appG-t6);
      display: flex;
      box-shadow:
        0 0 8px 2px rgba(60, 60, 60, 0.6),
        inset 0 0 4px 1px rgba(50, 50, 50, 0.4),
        inset 0 80px 40px 20px rgba(0, 0, 0, 0.1),
        inset 10px 20px 15px 10px rgba(55, 55, 55, 0.1);
    }

    @keyframes circleRotate {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(359deg);
      }
    }

    section.NSadmin .Info .circle2::before {
      content: "";
      border-radius: 50%;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      background-image: var(--appG-t3);
      position: absolute;
      animation: circleRotate 4s infinite linear;
      z-index: 888;
    }

    section.NSadmin .Info .circle2 {
      margin: 7rem;
      border: 0.4rem solid var(--appC-r6);
      border-radius: 50%;
      width: calc(100% - 14rem);
      height: auto;
      aspect-ratio: 1 / 1;
      background-image:
        var(--appG-t2),
        var(--appG-t6);
      display: flex;
      position: relative;
      box-shadow:
        0 0 8px 2px rgba(0, 0, 0, 0.6),
        5px 10px 16px 8px rgba(0, 0, 0, 0.6),
        10px 16px 16px 16px rgba(0, 0, 0, 0.2),
        inset 5px 0 4px 2px rgba(0, 0, 0, 0.4),
        inset 5px 80px 40px 20px rgba(0, 0, 0, 0.4),
        inset 10px 30px 15px 10px rgba(0, 0, 0, 0.2);
    }

    section.NSadmin .Info .circle3 {
      margin: 1.5rem;
      border: 0.2rem solid var(--appC-r6);
      border-radius: 50%;
      width: calc(100% - 3rem);
      height: auto;
      aspect-ratio: 1 / 1;
      background-image:
        var(--appG-t6),
        url(https://images.squarespace-cdn.com/content/v1/5fe4caeadae61a2f19719512/1615739943971-KX4G87OEO44LR5VJ0926/7.jpg);
      background-size: cover;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      box-shadow:
        2px 6px 8px 2px rgba(0, 0, 0, 0.6),
        inset 20px 10px 10px 50px rgba(0, 0, 0, 0.4),
        inset 0 40px 10px 80px rgba(0, 0, 0, 0.4);
      z-index: 999;
    }

    section.NSadmin .Info .circle3 h2 {
      color: var(--appC-r6);
      font-size: 8rem;
      text-shadow: 6px 6px 8px rgba(0, 0, 0, 0.6);
    }
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {
    section.NSadmin .Info {
      margin: 1.5rem;
      border-radius: 1rem;
      padding: 1rem;
      width: calc(100% / 1 - 3rem);
      height: auto;
      display: flex;
      position: relative;
      aspect-ratio: 1 / 1;
    }

    section.NSadmin .Info .circle1 {
      border: 0.1rem solid var(--appB-r3);
      border-radius: 50%;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      background-image:
        var(--appG-t2),
        var(--appG-t6);
      display: flex;
      box-shadow:
        0 0 8px 2px rgba(60, 60, 60, 0.6),
        inset 0 0 4px 1px rgba(50, 50, 50, 0.4),
        inset 0 80px 40px 20px rgba(0, 0, 0, 0.1),
        inset 10px 20px 15px 10px rgba(55, 55, 55, 0.1);
    }

    @keyframes circleRotate {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(359deg);
      }
    }

    section.NSadmin .Info .circle2::before {
      content: "";
      border-radius: 50%;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      background-image: var(--appG-t3);
      position: absolute;
      animation: circleRotate 4s infinite linear;
      z-index: 888;
    }

    section.NSadmin .Info .circle2 {
      margin: 7rem;
      border: 0.4rem solid var(--appC-r6);
      border-radius: 50%;
      width: calc(100% - 14rem);
      height: auto;
      aspect-ratio: 1 / 1;
      background-image:
        var(--appG-t2),
        var(--appG-t6);
      display: flex;
      position: relative;
      box-shadow:
        0 0 8px 2px rgba(0, 0, 0, 0.6),
        5px 10px 16px 8px rgba(0, 0, 0, 0.6),
        10px 16px 16px 16px rgba(0, 0, 0, 0.2),
        inset 5px 0 4px 2px rgba(0, 0, 0, 0.4),
        inset 5px 80px 40px 20px rgba(0, 0, 0, 0.4),
        inset 10px 30px 15px 10px rgba(0, 0, 0, 0.2);
    }

    section.NSadmin .Info .circle3 {
      margin: 1.5rem;
      border: 0.2rem solid var(--appC-r6);
      border-radius: 50%;
      width: calc(100% - 3rem);
      height: auto;
      aspect-ratio: 1 / 1;
      background-image:
        var(--appG-t6),
        url(https://images.squarespace-cdn.com/content/v1/5fe4caeadae61a2f19719512/1615739943971-KX4G87OEO44LR5VJ0926/7.jpg);
      background-size: cover;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      box-shadow:
        2px 6px 8px 2px rgba(0, 0, 0, 0.6),
        inset 20px 10px 10px 50px rgba(0, 0, 0, 0.4),
        inset 0 40px 10px 80px rgba(0, 0, 0, 0.4);
      z-index: 999;
    }

    section.NSadmin .Info .circle3 h2 {
      color: var(--appC-r6);
      font-size: 8rem;
      text-shadow: 6px 6px 8px rgba(0, 0, 0, 0.6);
    }
  }
}