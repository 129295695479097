@media (pointer:fine) {
  .app .NSlogin .open {
    display: block;
  }

  .app .NSlogin .close {
    display: none;
  }

  .app .NSlogin {
    padding: 1rem;
    min-height: 100vh;
    font-family: "Aleo-Regular";
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .app .NSlogin>.switchs {
    border-radius: 1rem 1rem 0 0;
    background-color: var(--appC-r5);
    width: calc(100% / 3);
    height: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    box-shadow: inset 0 -6px 16px -6px rgba(0, 0, 0, 0.25);
  }

  .app .NSlogin>.switchs button.switch {
    margin: 0;
    border-radius: 1rem 1rem 0 0;
    border-width: 0;
    padding: 0.6rem 2rem;
    width: fit-content;
    height: 3rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .app .NSlogin>.switchs button.switch svg {
    border-radius: 1rem 1rem 0 0;
    height: 1.8rem;
  }

  .app .NSlogin>.switchs button.switch.on {
    background-image: none;
    background-color: var(--appC-r1);
    color: var(--appC-r2);
    height: 4rem;
  }

  .app .NSlogin>.switchs button.switch.off {
    background: none;
    color: var(--appC-r2);
  }

  .app .NSlogin>.info {
    margin: 0;
    padding: 0;
    width: calc(100% / 3);
    background-image: var(--appG-t1);
    color: var(--appC-r1);
    font-size: 1.8rem;
    line-height: 3;
    text-align: center;
  }

  .app .NSlogin>.authorize {
    margin: 0;
    border-radius: 0 0 1rem 1rem;
    padding: 1rem 1rem 0 1rem;
    width: calc(100% / 3);
    background-color: var(--appC-r1);
    background-clip: border-box;
    box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.05);
    z-index: 222;
  }

  .app .NSlogin>.authorize h2 {
    margin-bottom: 2rem;
    font-size: 3.6rem;
  }

  .app .NSlogin>.authorize label {
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
  }

  .app .NSlogin>.authorize button {
    cursor: pointer;
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {
    .app .NSlogin .open {
      display: block;
    }

    .app .NSlogin .close {
      display: none;
    }

    .app .NSlogin {
      padding: 1rem;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    .app .NSlogin>.switchs {
      border-radius: 1rem 1rem 0 0;
      background-color: var(--appC-r5);
      width: calc(100% / 1.2);
      height: 6rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      box-shadow: inset 0 -6px 16px -6px rgba(0, 0, 0, 0.25);
    }

    .app .NSlogin>.switchs button.switch {
      margin: 0;
      border-radius: 1rem 1rem 0 0;
      border-width: 0;
      padding: 0.6rem 2rem;
      width: fit-content;
      height: 6rem;
      font-size: 2.4rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }

    .app .NSlogin>.switchs button.switch svg {
      border-radius: 1rem 1rem 0 0;
      height: 3rem;
    }

    .app .NSlogin>.switchs button.switch.on {
      background-image: none;
      background-color: var(--appC-r1);
      color: var(--appC-r2);
      height: 8rem;
    }

    .app .NSlogin>.switchs button.switch.off {
      background: none;
      color: var(--appC-r2);
    }

    .app .NSlogin>.info {
      margin: 0;
      padding: 0;
      width: calc(100% / 1.2);
      background-image: var(--appG-t1);
      color: var(--appC-r1);
      font-size: 3rem;
      line-height: 3;
      text-align: center;
    }

    .app .NSlogin>.authorize {
      margin: 0;
      border-radius: 0 0 1rem 1rem;
      padding: 1rem 1rem 0 1rem;
      width: calc(100% / 1.2);
      background-color: var(--appC-r1);
      background-clip: border-box;
      box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.05);
      z-index: 222;
    }

    .app .NSlogin>.authorize h2 {
      margin-bottom: 2rem;
      font-size: 3.6rem;
    }

    .app .NSlogin>.authorize label {
      margin-bottom: 1rem;
      font-size: 2.2rem;
    }

    .app .NSlogin>.authorize input {
      font-size: 3rem;
    }

    .app .NSlogin>.authorize button {
      margin-top: 3rem !important;
      height: 7rem;
      font-size: 3rem;
    }
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {
    .app .NSlogin .open {
      display: block;
    }

    .app .NSlogin .close {
      display: none;
    }

    .app .NSlogin {
      padding: 1rem;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    .app .NSlogin>.switchs {
      border-radius: 1rem 1rem 0 0;
      background-color: var(--appC-r5);
      width: calc(100% / 1.2);
      height: 6rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      box-shadow: inset 0 -6px 16px -6px rgba(0, 0, 0, 0.25);
    }

    .app .NSlogin>.switchs button.switch {
      margin: 0;
      border-radius: 1rem 1rem 0 0;
      border-width: 0;
      padding: 0.6rem 2rem;
      width: fit-content;
      height: 6rem;
      font-size: 2.4rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }

    .app .NSlogin>.switchs button.switch svg {
      border-radius: 1rem 1rem 0 0;
      height: 3rem;
    }

    .app .NSlogin>.switchs button.switch.on {
      background-image: none;
      background-color: var(--appC-r1);
      color: var(--appC-r2);
      height: 8rem;
    }

    .app .NSlogin>.switchs button.switch.off {
      background: none;
      color: var(--appC-r2);
    }

    .app .NSlogin>.info {
      margin: 0;
      padding: 0;
      width: calc(100% / 1.2);
      background-image: var(--appG-t1);
      color: var(--appC-r1);
      font-size: 3rem;
      line-height: 3;
      text-align: center;
    }

    .app .NSlogin>.authorize {
      margin: 0;
      border-radius: 0 0 1rem 1rem;
      padding: 1rem 1rem 0 1rem;
      width: calc(100% / 1.2);
      background-color: var(--appC-r1);
      background-clip: border-box;
      box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.05);
      z-index: 222;
    }

    .app .NSlogin>.authorize h2 {
      margin-bottom: 2rem;
      font-size: 3.6rem;
    }

    .app .NSlogin>.authorize label {
      margin-bottom: 1rem;
      font-size: 2.2rem;
    }

    .app .NSlogin>.authorize input {
      font-size: 3rem;
    }

    .app .NSlogin>.authorize button {
      margin-top: 3rem !important;
      height: 7rem;
      font-size: 3rem;
    }
  }
}