@media (pointer:fine) {
  @media screen {
    ::-webkit-scrollbar {
      width: .6rem;
      height: 0rem
    }

    ::-webkit-scrollbar-track:enabled {
      background: var(--appC-r7)
    }

    ::-webkit-scrollbar-thumb:vertical {
      border-radius: .2rem;
      background: linear-gradient(0deg, var(--appC-r6), var(--appC-r5), var(--appC-r6))
    }

    ::-webkit-scrollbar-thumb:horizontal {
      background: var(--appC-r7)
    }
  }

  section.NSadmin {
    background-image:
      var(--appG-t1),
      var(--appG-t6);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-shadow: inset 0 0 60px 20px rgba(0, 0, 0, 0.2);
  }

  section>div.NSpanel {
    margin: 6rem;
    border: 0.1rem solid var(--appB-r2);
    border-radius: 3rem;
    padding: 2rem 4rem;
    background-image:
      var(--appG-t6),
      var(--appG-t2);
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    box-shadow:
      0 40px 40px 15px rgba(0, 0, 0, 0.4),
      inset 200px -200px 400px 0 rgba(0, 0, 0, 0.2);
  }

  section>div.NSpanel::before {
    content: "";
    border-radius: 3rem;
    background-image:
      var(--appG-t6),
      url(https://images.squarespace-cdn.com/content/v1/5fe4caeadae61a2f19719512/1615739943971-KX4G87OEO44LR5VJ0926/7.jpg);
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.4)) opacity(0.1);
    width: 100%;
    height: 80rem;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 10%, rgba(0, 0, 0, 1) 100%);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 10%, rgba(0, 0, 0, 1) 100%);
    z-index: 111;
  }

  section>div.NSpanel h2 {
    padding: 0 0 1.4rem 0;
    font-size: 3rem;
    line-height: 1;
  }

  section>div.NSpanel .avatar {
    margin: 0 1rem 1rem 0;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.05);
    width: calc(100% - 1rem);
    aspect-ratio: 1/1;
    overflow: hidden;
  }

  section>div.NSpanel :where(div.NS100g, div.NS80g, div.NS60g, div.NS50g, div.NS40g, div.NS25g, div.NS20g) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    z-index: 888;
  }

  section>div.NSpanel div.NS100g {
    width: calc(100% / 1);
  }

  section>div.NSpanel div.NS80g {
    width: calc(80% / 1);
  }

  section>div.NSpanel div.NS60g {
    width: calc(60% / 1);
  }

  section>div.NSpanel div.NS50g {
    width: calc(50% / 1);
  }

  section>div.NSpanel div.NS40g {
    width: calc(40% / 1);
  }

  section>div.NSpanel div.NS25g {
    width: calc(25% / 1);
  }

  section>div.NSpanel div.NS20g {
    width: calc(20% / 1);
  }

  section.NSadmin :where(.Blog, .Category, .Gallery, .Media, .Menutop, .Message, .Settings, .Sidebar, .Userlist) {
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    width: calc(100% - 2rem);
    background-image: var(--appG-t4);
    font-family: "Aleo-Regular";
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    box-shadow:
      inset 0 0 10px 0 rgba(0, 0, 0, 0.2),
      4px 10px 8px 4px rgba(0, 0, 0, 0.5);
  }

  section>div.NSpanel .quill {
    margin: 0 0 1rem 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    outline: none !important;
    width: 100% !important;
    font-size: 1.6rem !important;
    display: block !important;
  }

  section>div.NSpanel .quill .ql-stroke {
    stroke: var(--appC-r7) !important;
  }

  section>div.NSpanel .quill .ql-toolbar {
    margin: 0 !important;
    padding: 1rem !important;
    box-sizing: border-box !important;
    border-radius: 1rem 1rem 0rem 0rem !important;
    border: 1px solid var(--appB-r3) !important;
    outline: none !important;
    background-color: var(--appC-r6) !important;
    width: 100% !important;
    font-size: 1.8rem !important;
    display: block !important;
  }

  section>div.NSpanel .quill .ql-toolbar>span {
    margin-right: 2rem !important;
  }

  section>div.NSpanel .quill .ql-toolbar>span :where(.ql-picker, .ql-picker-label, .ql-picker-label::before) {
    font-size: 1.8rem !important;
    line-height: 4rem !important;
    height: 4rem !important;
  }

  section>div.NSpanel .quill .ql-toolbar>span .ql-header {
    width: 12rem !important;
  }

  section>div.NSpanel .quill .ql-toolbar>span>span svg {
    position: absolute !important;
    right: 0 !important;
    top: 50% !important;
    transform: translate(0%, -50%) !important;
  }

  section>div.NSpanel .quill .ql-toolbar>span>button {
    margin: 0.6rem !important;
    width: 1.8rem !important;
    height: 1.8rem !important;
    position: relative !important;
  }

  section>div.NSpanel .quill .ql-toolbar>span>button svg {
    position: absolute !important;
    right: 0 !important;
    top: 50% !important;
    transform: translate(0%, -50%) !important;
  }

  section>div.NSpanel .quill .ql-container {
    margin: 0 !important;
    padding: 1rem !important;
    box-sizing: border-box !important;
    border-radius: 0rem 0rem 1rem 1rem !important;
    border: 1px solid var(--appB-r3) !important;
    outline: none !important;
    width: 100% !important;
    font-size: 1.4rem !important;
    display: block !important;
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:portrait) {
  @media (pointer:coarse) {
    section.NSadmin {
      background-image:
        var(--appG-t1),
        var(--appG-t6);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      box-shadow: inset 0 0 60px 20px rgba(0, 0, 0, 0.2);
    }

    section>div.NSpanel {
      margin: 6rem;
      border: 0.1rem solid var(--appB-r2);
      border-radius: 3rem;
      padding: 2rem 4rem;
      background-image:
        var(--appG-t6),
        var(--appG-t2);
      min-height: 100vh;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      backdrop-filter: blur(10px);
      box-shadow:
        0 40px 40px 15px rgba(0, 0, 0, 0.4),
        inset 200px -200px 400px 0 rgba(0, 0, 0, 0.2);
    }

    section>div.NSpanel h2 {
      padding: 0 0 1.4rem 0;
      font-size: 3rem;
      line-height: 1;
    }

    section>div.NSpanel .avatar {
      margin: 0 1rem 1rem 0;
      border-radius: 1rem;
      background-color: rgba(0, 0, 0, 0.05);
      width: calc(100% - 1rem);
      aspect-ratio: 1/1;
      overflow: hidden;
    }

    section>div.NSpanel :where(div.NS100g, div.NS80g, div.NS60g, div.NS50g, div.NS40g, div.NS25g, div.NS20g) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    section>div.NSpanel div.NS100g {
      width: calc(100% /1);
    }

    section>div.NSpanel div.NS80g {
      width: calc(80% /1);
    }

    section>div.NSpanel div.NS60g {
      width: calc(60% /1);
    }

    section>div.NSpanel div.NS50g {
      width: calc(50% /1);
    }

    section>div.NSpanel div.NS40g {
      width: calc(40% /1);
    }

    section>div.NSpanel div.NS25g {
      width: calc(25% /1);
    }

    section>div.NSpanel div.NS20g {
      width: calc(20% /1);
    }
  }
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */

@media (orientation:landscape) {
  @media (pointer:coarse) {
    section.NSadmin {
      background-image:
        var(--appG-t1),
        var(--appG-t6);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      box-shadow: inset 0 0 60px 20px rgba(0, 0, 0, 0.2);
    }

    section>div.NSpanel {
      margin: 6rem;
      border: 0.1rem solid var(--appB-r2);
      border-radius: 3rem;
      padding: 2rem 4rem;
      background-image:
        var(--appG-t6),
        var(--appG-t2);
      min-height: 100vh;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      backdrop-filter: blur(10px);
      box-shadow:
        0 40px 40px 15px rgba(0, 0, 0, 0.4),
        inset 200px -200px 400px 0 rgba(0, 0, 0, 0.2);
    }

    section>div.NSpanel h2 {
      padding: 0 0 1.4rem 0;
      font-size: 3rem;
      line-height: 1;
    }

    section>div.NSpanel .avatar {
      margin: 0 1rem 1rem 0;
      border-radius: 1rem;
      background-color: rgba(0, 0, 0, 0.05);
      width: calc(100% - 1rem);
      aspect-ratio: 1/1;
      overflow: hidden;
    }

    section>div.NSpanel :where(div.NS100g, div.NS80g, div.NS60g, div.NS50g, div.NS40g, div.NS25g, div.NS20g) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    section>div.NSpanel div.NS100g {
      width: calc(100% /1);
    }

    section>div.NSpanel div.NS80g {
      width: calc(80% /1);
    }

    section>div.NSpanel div.NS60g {
      width: calc(60% /1);
    }

    section>div.NSpanel div.NS50g {
      width: calc(50% /1);
    }

    section>div.NSpanel div.NS40g {
      width: calc(40% /1);
    }

    section>div.NSpanel div.NS25g {
      width: calc(25% /1);
    }

    section>div.NSpanel div.NS20g {
      width: calc(20% /1);
    }
  }
}