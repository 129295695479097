:root {

  --appG-t1: linear-gradient(135deg, rgb(172, 137, 99), rgb(59, 40, 26));
  --appG-t2: linear-gradient(135deg, rgb(70, 76, 79), rgb(17, 19, 23));
  --appG-t3: linear-gradient(135deg, rgba(250, 218, 175, 1), rgba(250, 218, 175, 0));
  --appG-t4: linear-gradient(135deg, rgb(250, 218, 175), rgb(59, 40, 26));
  --appG-t5: linear-gradient(135deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  --appG-t6: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  --appG-t7: linear-gradient(135deg, rgba(172, 137, 99, 1), rgba(59, 40, 26, 0.4));

  --appC-r1: rgb(255, 255, 255);
  --appC-r2: rgb(0, 0, 0);
  --appC-r3: rgb(172, 137, 99);
  --appC-r4: rgb(59, 40, 26);
  --appC-r5: rgb(205, 205, 205);
  --appC-r6: rgb(250, 218, 175);
  --appC-r7: rgb(17, 19, 23);

  --appB-r1: rgb(190, 190, 190);
  --appB-r2: rgba(0, 0, 0, 0.2);
  --appB-r3: rgba(0, 0, 0, 0.6);

}

.app p {
  font-size: 2rem
}

.app :where(input[type=text], input[type=password], input[type=date], input[type=datetime], input[type=number], input[type=search], input[type=time], input[type=url], input[type=email], input[type=file], textarea, select, button) {
  margin: 0 0 1rem 0;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  border: 1px solid var(--appB-r3);
  outline: none;
  background-color: var(--appC-r5);
  width: 100%;
  font-size: 1.6rem;
  color: var(--appC-r2);
  display: block
}

.app :where(textarea, select, button) {
  border-radius: 1rem
}

.app button {
  border-width: 0;
  background-image: var(--appG-t1);
  color: var(--appC-r1);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.app fieldset {
  margin: 0 0 1rem 0;
  width: 100%
}

.app fieldset>input[type=radio] {
  margin: 1rem;
  border-radius: 1rem;
  border: 1px solid var(--appB-r3);
  width: calc(100% / 4 - 2rem);
  aspect-ratio: 1 / 1;
  -webkit-appearance: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  filter: grayscale(100%);
  transition-duration: 0.5s
}

.app fieldset>input[type=radio]:checked {
  margin: 0.25rem;
  width: calc(100% / 4 - 0.5rem);
  aspect-ratio: 1 / 1;
  filter: grayscale(0%)
}

/*  */

.app table {
  margin: 0 0 1rem 0;
  border-radius: 1rem;
  color: var(--appC-r4);
  overflow: hidden;
}

.app thead {
  border-bottom: 1px solid var(--appB-r3);
  font-size: 1.8rem;
  line-height: 2;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%)
}

.app thead tr {
  width: 100%;
  display: table;
  table-layout: fixed
}

.app tbody {
  max-height: 30rem;
  font-size: 1.2rem;
  display: block;
  overflow: auto
}

.app tbody tr {
  margin: 0;
  border-bottom: 1px dashed var(--appB-r3);
  width: 100%;
  display: table;
  table-layout: fixed
}

.app tbody tr:nth-child(even) {
  background-color: transparent
}

.app tbody tr td {
  padding: 1.2rem 0;
  display: table-cell;
  vertical-align: middle
}

.app tbody tr td.tdThumb {
  aspect-ratio: 1 / 1
}

.app tbody tr td img {
  margin: 0 1rem 0 0;
  border-radius: 1rem;
  border: 1px solid var(--appB-r3);
  width: auto;
  height: 4rem;
  aspect-ratio: 1 / 1
}

.app tbody tr td :where(p, a) {
  padding: 0 2rem;
  color: var(--appC-r4)
}

.app tbody tr td button {
  margin: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  cursor: pointer
}

.app tbody tr td button svg {
  height: 2rem;
  width: auto
}

.app tbody tr td button svg g {
  fill: var(--appC-r4)
}